import { useEffect, useState } from 'react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { styled } from '@mui/material/styles';
import ReactWordcloud from 'react-wordcloud';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import {
  wordCloudPatternSelector,
  setWordCloudPattern,
  wordCloudHiddenWordsSelector,
} from '../sentimentMessageSlice';
import CallTranscriptionTable from './CallTranscriptionTable';

const PREFIX = 'SentimentWordClouds';

const classes = {
  root: `${PREFIX}-root`,
  speaker: `${PREFIX}-speaker`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
  [`& .${classes.speaker}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
}));

export default function SentimentWordClouds({
  words,
  messages,
  msgKey,
  displayNote,
  displayTranscription,
  colorCodePerSpeaker,
}) {
  const dispatch = useDispatch();
  const pattern = useSelector(wordCloudPatternSelector);
  const wordCloudHiddenWords = useSelector(wordCloudHiddenWordsSelector);
  const [filteredWordClouds, setFilteredWordClouds] = useState(words);

  useEffect(() => {
    try {
      if (wordCloudHiddenWords && Array.isArray(wordCloudHiddenWords)) {
        const toHideWords = wordCloudHiddenWords.map((item) => item?.Word?.toLowerCase());
        const wordClouds = words.filter((item) => !toHideWords.includes(item?.text?.toLowerCase()));
        setFilteredWordClouds(wordClouds);
      } else {
        setFilteredWordClouds(words);
      }
    } catch (error) {
      console.error('Error', error);
      setFilteredWordClouds(words);
    }
  }, [words, wordCloudHiddenWords]);

  useEffect(() => {
    dispatch(setWordCloudPattern(''));
  }, []);

  const callbacks = {
    onWordClick: (word) => {
      dispatch(setWordCloudPattern(word.text));
    },
    getWordTooltip: (word) => `${word.text} (${word.value})`,
  };

  return (
    <StyledRoot className={classes.root}>
      {filteredWordClouds && filteredWordClouds?.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <ReactWordcloud
              callbacks={callbacks}
              maxWords={50}
              options={{
                colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
                rotations: 1,
                rotationAngles: [0],
                fontSizes: [15, 80],
                transitionDuration: 1000,
                scale: 'sqrt',
                spiral: 'archimedean',
                enableTooltip: true,
                deterministic: true,
                fontFamily: 'impact',
                fontStyle: 'normal',
                fontWeight: 'normal',
              }}
              words={filteredWordClouds}
            />
          </Grid>
          {displayTranscription &&
            displayNote &&
            filteredWordClouds &&
            filteredWordClouds?.length &&
            messages &&
            msgKey &&
            messages?.length > 0 && (
              <Grid item xs={12}>
                <Typography component="i" style={{ marginLeft: '10px' }} variant="caption">
                  Note: Click on word to highlight it at messages below.
                </Typography>{' '}
              </Grid>
            )}
          {displayTranscription && messages && msgKey && messages?.length > 0 && (
            <Grid item xs={12}>
              <CallTranscriptionTable
                colorCodePerSpeaker={colorCodePerSpeaker}
                displaySentimentColorNote={false}
                msgKey={msgKey}
                pattern={pattern}
                transcriptList={messages}
              />
            </Grid>
          )}
        </Grid>
      )}
    </StyledRoot>
  );
}
