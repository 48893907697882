import CallTranscriptionTable from '../CallTranscriptionTable';

export default function AssemblyAICallTranscription({
  transcriptList,
  colorCodePerSpeaker,
  componentTitle,
  displaySentimentColorNote,
}) {
  return (
    <CallTranscriptionTable
      colorCodePerSpeaker={colorCodePerSpeaker}
      componentTitle={componentTitle}
      displaySentimentColorNote={displaySentimentColorNote}
      msgKey="text"
      transcriptList={transcriptList}
    />
  );
}
