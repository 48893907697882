import { styled } from '@mui/material/styles';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Tooltip, Typography } from '@mui/material';
import { SentimentType } from '../../../utils/sentimentUtil';

const PREFIX = 'SentimentIcon';

const classes = {
  root: `${PREFIX}-root`,
  flexWrap: `${PREFIX}-flexWrap`,
  positive: `${PREFIX}-positive`,
  neutral: `${PREFIX}-neutral`,
  negative: `${PREFIX}-negative`,
  mixed: `${PREFIX}-mixed`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },

  [`& .${classes.flexWrap}`]: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  [`& .${classes.positive}`]: {
    fill: 'green',
  },

  [`& .${classes.neutral}`]: {
    fill: 'black',
  },

  [`& .${classes.negative}`]: {
    fill: 'red',
  },

  [`& .${classes.mixed}`]: {
    fill: 'blue',
  },
}));

export default function SentimentIcon({ sentiment }) {
  const getSentimentIcon = () => {
    switch (sentiment) {
      case SentimentType.POSITIVE:
        return <SentimentVerySatisfiedIcon className={classes[sentiment.toLowerCase()]} />;
      case SentimentType.NEGATIVE:
        return <SentimentVeryDissatisfiedIcon className={classes[sentiment.toLowerCase()]} />;
      case SentimentType.NEUTRAL:
        return <SentimentDissatisfiedIcon className={classes[sentiment.toLowerCase()]} />;
      case SentimentType.MIXED:
        return <EmojiEmotionsIcon className={classes[sentiment.toLowerCase()]} />;
      default:
        return '';
    }
  };

  return (
    <StyledRoot className={classes.root}>
      {sentiment && (
        <Tooltip title={sentiment}>
          <Typography>{getSentimentIcon(sentiment)}</Typography>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
