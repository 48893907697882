import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Hidden, ButtonGroup, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import RefreshIcon from '@mui/icons-material/Refresh';
import SentimentWordClouds from './SentimentWordClouds';
import { useSelector } from 'react-redux';
import { getWordClouds } from '../../../utils/wordCloudUtil';
import { SentimentAnalysisPlatform } from '../../../utils/sentimentUtil';
import SentimentQueryFilterDialog from './SentimentQueryFilterDialog';
import { sentimentMessagesSelector } from '../sentimentMessageSlice';
import SentimentMessageList from './SentimentMessageList';
import CustomizedProgressLoader from '../../shared/CustomizedProgressLoader';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'SentimentDashboard';

const classes = {
  root: `${PREFIX}-root`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  buttonItem: `${PREFIX}-buttonItem`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'start',
    gap: theme.spacing(2),
    '& .MuiButton-root': {
      borderRadius: 5,
    },
  },

  [`& .${classes.buttonItem}`]: {
    marginLeft: theme.spacing(1),
  },
}));

export default function SentimentDashboard({ sentimentAnalysisPlatform }) {
  const { t } = useTranslation();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [fetchMessages, setFetchMessages] = useState(false);
  const messages = useSelector(sentimentMessagesSelector);

  const [words, setWords] = useState([]);

  useEffect(() => {
    patchWordClouds(messages);
  }, [messages]);

  const patchWordClouds = (messages) => {
    try {
      let msgClouds = [];
      let words = [];
      switch (sentimentAnalysisPlatform) {
        case SentimentAnalysisPlatform.assembly_ai_sentiment_analysis:
          msgClouds = messages.map((item) => item?.Summary).join(' ');
          words = getWordClouds(msgClouds);
          setWords(words);
          break;
        default:
          setWords([]);
          break;
      }
    } catch (error) {
      console.Error('Error', error);
    }
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleFilter = () => {
    setOpenFilterDialog(true);
  };

  const retrieveMessages = () => {
    setFetchMessages(true);
  };

  return (
    <StyledRoot className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <ButtonGroup className={classes.buttonGroup}>
            <Hidden smDown>
              <PortalButton
                className={classes.button}
                component="span"
                data-tour="rtQueryMsg"
                onClick={handleFilter}
                startIcon={<TuneIcon />}
                variant="contained"
              >
                {t('Query')}
              </PortalButton>

              <PortalButton
                className={classes.buttonItem}
                component="span"
                data-tour="rtQueryMsgRefresh"
                onClick={retrieveMessages}
                startIcon={<RefreshIcon />}
                variant="contained"
              >
                {t('Retrieve Messages')}
              </PortalButton>
            </Hidden>

            <Hidden mdUp>
              <PortalIconButton
                aria-label="change message query"
                component="span"
                onClick={handleFilter}
                size="medium"
                variant="contained"
              >
                <Tooltip disableFocusListener title={t('Change Message Query')}>
                  <TuneIcon />
                </Tooltip>
              </PortalIconButton>

              <PortalIconButton
                aria-label="Retrieve Messages"
                component="span"
                onClick={retrieveMessages}
                size="medium"
                variant="contained"
              >
                <Tooltip disableFocusListener title={t('Retrieve Messages')}>
                  <RefreshIcon />
                </Tooltip>
              </PortalIconButton>
            </Hidden>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <SentimentWordClouds words={words} />
          <SentimentMessageList sentimentAnalysisPlatform={sentimentAnalysisPlatform} />
        </Grid>
      </Grid>
      <SentimentQueryFilterDialog
        fetchMessages={fetchMessages}
        openDialog={openFilterDialog}
        propagateOnClose={() => handleCloseFilterDialog(true)}
        unSetFetchMessages={() => setFetchMessages(false)}
      />
      <CustomizedProgressLoader />
    </StyledRoot>
  );
}
