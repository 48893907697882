import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { SentimentType } from '../../../utils/sentimentUtil';

const PREFIX = 'CallTranscriptionTable';

const classes = {
  root: `${PREFIX}-root`,
  tableContainer: `${PREFIX}-tableContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  graphContainer: `${PREFIX}-graphContainer`,
  speaker: `${PREFIX}-speaker`,
  notes: `${PREFIX}-notes`,
};
const pageThemColor = '#0097a7';

const StyledRoot = styled('div')({
  [`& .${classes.tableContainer}`]: {
    boxShadow: '0 1px 1px 0 #4dd0e182',
    borderRadius: '5px',
    maxHeight: 900,
  },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '18px' },
  [`& .${classes.graphContainer}`]: {
    backgroundColor: '#f8fdff',
    padding: '8px',
    borderRadius: '10px',
  },
  [`& .${classes.speaker}`]: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
});

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: pageThemColor,
  color: theme.palette.common.white,
  padding: '2px',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: '5px 10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const subTitleSections = {
  start: 'SENTIMENT COLORS: ',
  black: `${SentimentType.NEUTRAL}, `,
  green: `${SentimentType.POSITIVE}, `,
  red: `${SentimentType.NEGATIVE}`,
};

export default function CallTranscriptionTable({
  transcriptList = [],
  colorCodePerSpeaker,
  pattern = '',
  msgKey = 'text',
  displaySentimentColorNote = false,
  componentTitle = 'Call Transcription',
}) {
  const getSegmentText = (segment) => {
    let segmentText = segment[msgKey] ? `<span>${segment[msgKey]}</span>` : '';
    if (displaySentimentColorNote) {
      try {
        segmentText = `<span style="color: ${
          segment?.sentiment === SentimentType.POSITIVE
            ? 'green'
            : segment?.sentiment === SentimentType.NEGATIVE
              ? 'red'
              : 'black'
        }">${segment.text}</span>`;
      } catch (error) {
        console.error('Error', error);
      }
    }
    return segmentText;
  };

  const getSpeakerName = (speaker) => {
    return `[Speaker ${speaker?.trim()}]`;
  };

  return (
    <StyledRoot>
      {transcriptList && transcriptList?.length > 0 && (
        <Box className={classes.graphContainer}>
          <TableContainer className={classes.tableContainer}>
            <Table aria-label={componentTitle} stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    transition: 'all .1s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.0)',
                    },
                  }}
                >
                  <StyledTableHeadCell colSpan="2">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography className={classes.sectionTitle}>{componentTitle}</Typography>
                      {displaySentimentColorNote ? (
                        <Typography
                          component="span"
                          sx={{ backgroundColor: 'white', padding: '5px 5px' }}
                          variant="caption"
                        >
                          <span style={{ color: 'black' }}>{subTitleSections.start}</span>
                          <span style={{ color: 'black', fontWeight: 900 }}>
                            {subTitleSections.black}
                          </span>{' '}
                          <span style={{ color: 'green', fontWeight: 900 }}>
                            {subTitleSections.green}
                          </span>{' '}
                          <span style={{ color: 'red', fontWeight: 900 }}>
                            {subTitleSections.red}
                          </span>
                        </Typography>
                      ) : null}
                    </Box>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.scrollableTableBody}>
                {transcriptList.map((segment, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableBodyCell
                      className={classes.speaker}
                      style={{
                        color:
                          colorCodePerSpeaker && colorCodePerSpeaker[segment?.speaker?.trim()]
                            ? colorCodePerSpeaker[segment?.speaker?.trim()]
                            : 'black',
                      }}
                    >
                      {' '}
                      {getSpeakerName(segment?.speaker?.trim())}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          pattern && pattern.length > 0
                            ? getSegmentText(segment).replace(
                                new RegExp(pattern, 'gi'),
                                '<span style="background-color:yellow;font-weight:bold;font-size:14">$&</span>',
                              )
                            : getSegmentText(segment),
                        ),
                      }}
                    />
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </StyledRoot>
  );
}
