import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { sentimentMessagesSelector } from '../sentimentMessageSlice';
import SentimentMessage from './SentimentMessage';
import { selReceiverSelector } from '../messageSlice';
import { MasterFeatureTypeRef } from '../../../utils/accountUtil/constants';
import { getReceiversFromStore } from '../../../utils/reduxUtil';

const PREFIX = 'SentimentMessageList';

const classes = {
  root: `${PREFIX}-root`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  buttonItem: `${PREFIX}-buttonItem`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  noRecords: `${PREFIX}-noRecords`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'start',
    gap: theme.spacing(2),
  },

  [`& .${classes.buttonItem}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.section}`]: { marginBottom: '20px' },
  [`& .${classes.sectionTitle}`]: { fontWeight: 'bold', paddingLeft: '2px', fontSize: '18px' },
  [`& .${classes.noRecords}`]: { display: 'flex', justifyContent: 'center' },
}));

export default function SentimentMessageList({ sentimentAnalysisPlatform }) {
  const messages = useSelector(sentimentMessagesSelector);
  const selReceiver = useSelector(selReceiverSelector);
  const [displayTranscription, setDisplayTranscription] = useState(false);

  const { t } = useTranslation();
  const [msgs, setMsgs] = useState([]);

  useEffect(() => {
    setMsgs(messages);
  }, [messages]);

  useEffect(() => {
    if (selReceiver) {
      handleDetermineCallTranscriptionDisplay(selReceiver);
    } else {
      console.log('no selected receiver', selReceiver);
      setDisplayTranscription(false);
    }
  }, [selReceiver]);

  const handleDetermineCallTranscriptionDisplay = (receiverNumber) => {
    try {
      const receivers = getReceiversFromStore();
      const givenReceiver = receivers?.find((rItem) => rItem?.number === receiverNumber);
      if (
        givenReceiver &&
        givenReceiver?.features?.find(
          (ele) =>
            ele?.masterFeature.featureTypeRef === MasterFeatureTypeRef.TRANSCRIPT &&
            ele?.status === 'ENABLE',
        )
      ) {
        setDisplayTranscription(true);
      } else {
        setDisplayTranscription(false);
      }
    } catch (error) {
      setDisplayTranscription(false);
      console.error('Error', error);
    }
  };

  return (
    <StyledRoot className={classes.root}>
      <Grid container>
        {msgs &&
          msgs?.length > 0 &&
          msgs.map((item, key) => (
            <SentimentMessage
              displayTranscription={displayTranscription} // TODO: make update to this use displayTranscription
              expand
              key={key}
              message={item}
              sentimentAnalysisPlatform={sentimentAnalysisPlatform}
              staffMode={false}
            />
          ))}

        {msgs && msgs?.length === 0 && (
          <Grid item xs={12}>
            <Box className={classes.noRecords}>
              <Typography variant="overline">{t('no records found')}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </StyledRoot>
  );
}
