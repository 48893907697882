export const SentimentAnalysisPlatform = {
  assembly_ai_sentiment_analysis: 'assembly_ai_sentiment_analysis',
  aws_sentiment_analysis: 'aws_sentiment_analysis',
};

export const SentimentType = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
  MIXED: 'MIXED',
};

export const SentimentAnalysisConstants = {
  callTranscription: 'callTranscription',
};
