import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { createReminder } from '../messageSlice';
import { remindersSelector, selReceiverSelector } from '../messageSlice';
import Reminder from './Reminder';
import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import moment from 'moment';
import { portalUserSelector, setPortalSnackInfo, timezoneSelector } from '../../../app/authSlice';
import PortalButton from '../../shared/components/PortalButton';
import PortalIconButton from '../../shared/components/PortalIconButton';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    background: 'transparent',
    padding: theme.spacing(2),
  },
}));

export default function Reminders() {
  const reminders = useSelector(remindersSelector);
  const defaultTimezone = useSelector(timezoneSelector);
  const selReceiver = useSelector(selReceiverSelector);
  const [displayType, setDisplayType] = useState('grid');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('Single');
  const [dateRangeError, setDateRangeError] = useState('');
  const [description, setDescription] = useState('');
  const [checked, setChecked] = useState(false);
  const user = useSelector(portalUserSelector);

  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'description':
        setDescription(event.target.value);
        break;
      case 'type':
        setType(event.target.value);
        break;
      case 'checked':
        setChecked(event.target.checked);
        setSelectedEndDate('');
        break;
      default:
        setType(event.target.value);
    }
  };

  const handleSave = () => {
    var newReminder = {
      frequency: type.toLowerCase(),
      messageText: description,
      messageHtml: description,
      startDateTime: moment
        .tz(selectedDate, defaultTimezone)
        .tz('America/New_York')
        .format('YYYYMMDDHHmm')
        .toString(),
    };

    if (
      moment
        .tz(selectedDate, defaultTimezone)
        .tz('America/New_York')
        .format('YYYYMMDDHHmm')
        .toString() === 'Invalid date'
    ) {
      dispatch(setPortalSnackInfo({ severity: 'error', message: 'Invalid Start date' }));
      return;
    }

    if (type.toLowerCase() !== 'single') {
      if (!checked) {
        if (selectedEndDate) {
          if (
            moment
              .tz(selectedEndDate, defaultTimezone)
              .tz('America/New_York')
              .format('YYYYMMDDHHmm')
              .toString() === 'Invalid date'
          ) {
            dispatch(setPortalSnackInfo({ severity: 'error', message: 'Invalid End Date' }));
            return;
          }

          if (moment(selectedEndDate).diff(moment(selectedDate), 'minute') <= 0) {
            dispatch(
              setPortalSnackInfo({
                severity: 'error',
                message: 'End date cannot be less than or equal to start date',
              }),
            );
            return;
          }
        } else {
          dispatch(setPortalSnackInfo({ severity: 'error', message: 'Select End Date' }));
          return;
        }
        newReminder.endDateTime = moment
          .tz(selectedEndDate, defaultTimezone)
          .tz('America/New_York')
          .format('YYYYMMDDHHmm')
          .toString();
      }
    }
    dispatch(createReminder(selReceiver, newReminder, user.login));
    setOpen(false);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleDateChange = (event) => {
    switch (event.target.name) {
      case 'startDate':
        setSelectedDate(event.target.value);
        break;
      case 'endDate':
        setSelectedEndDate(event.target.value);
        break;
      default:
    }
  };

  return (
    <StyledGrid className={classes.root} container>
      <Grid item xs={12}>
        <ButtonGroup color="primary">
          <PortalIconButton
            aria-label="upload picture"
            className={classes.buttonGroup}
            color={open ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleOpen()}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="Add Reminder">
              <AddIcon />
            </Tooltip>
          </PortalIconButton>

          <PortalIconButton
            aria-label="upload picture"
            className={classes.buttonGroup}
            color={displayType === 'grid' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('grid')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="Grid">
              <ViewCompactIcon />
            </Tooltip>
          </PortalIconButton>
          <PortalIconButton
            aria-label="upload picture"
            color={displayType === 'list' ? 'secondary' : 'primary'}
            component="span"
            onClick={() => handleDisplayChange('list')}
            size="medium"
            variant="contained"
          >
            <Tooltip disableFocusListener title="List">
              <ListIcon />
            </Tooltip>
          </PortalIconButton>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <Grid className={classes.root} container spacing={2}>
          {selReceiver &&
            reminders &&
            reminders.map((row, index) => (
              <Reminder content={row} key={index} size={displayType === 'grid' ? 3 : 12} />
            ))}
        </Grid>
      </Grid>
      {reminders.length === 0 && (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
          <Typography variant="overline">No reminders Found</Typography>
        </Grid>
      )}

      <Dialog
        aria-labelledby="simple-dialog-title"
        className={classes.dialog}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Create Reminder</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <DialogContentText id="alert-dialog-description">
            Schedules a reminder based on frequency which may repeat until end date
            {dateRangeError}
          </DialogContentText>

          <FormControl className={classes.formControl} fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
            <Select
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              name="type"
              onChange={handleChange}
              value={type}
            >
              {['Single', 'Hourly', 'Daily', 'Weekly', 'Workday', 'Monthly', 'Annually'].map(
                (value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={selectedDate}
              id="datetime-local"
              label="Start Time"
              name="startDate"
              onChange={handleDateChange}
              type="datetime-local"
              variant="standard"
            />
          </FormControl>

          {type !== 'Single' && (
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                defaultValue={selectedEndDate}
                disabled={checked}
                id="datetime-local2"
                label="End Time"
                name="endDate"
                onChange={handleDateChange}
                type="datetime-local"
                variant="standard"
              />
            </FormControl>
          )}

          {type !== 'Single' && (
            <FormControlLabel
              control={<Checkbox checked={checked} name="checked" onChange={handleChange} />}
              label="Never Expires"
            />
          )}

          <FormControl className={classes.formControl} fullWidth>
            <TextField
              label="Message Text"
              multiline
              name="description"
              onChange={handleChange}
              required
              rows={4}
              value={description}
              variant="standard"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            disabled={description.length === 0}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </PortalButton>
          <PortalButton autoFocus color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
    </StyledGrid>
  );
}
