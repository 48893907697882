import { SentimentType } from '../sentimentUtil';

const ZERO = 0;
const ONE = 1;

const getSentimentColor = (sentiment) => {
  return sentiment === SentimentType.POSITIVE
    ? '#3EC9C4'
    : sentiment === SentimentType.NEGATIVE
      ? '#d26463'
      : '#6569b0';
};

export const getSentimentCountsBySpeaker = (speechSegmentList) => {
  let sentimentCountsBySpeaker = {};
  try {
    const counts = speechSegmentList.reduce((countsInner, item) => {
      let { speaker } = item;
      const { sentiment } = item;
      speaker = speaker?.trim();
      if (speaker) {
        if (speaker && !countsInner[speaker]) {
          countsInner[speaker] = {};
        }
        countsInner[speaker][sentiment] = (countsInner[speaker][sentiment] || ZERO) + ONE;
      }
      return countsInner;
    }, {});
    sentimentCountsBySpeaker = Object.entries(counts).reduce((result, [speaker, countsInner]) => {
      result[speaker] = Object.entries(countsInner).map(([sentiment, count]) => ({
        label: sentiment,
        value: count,
        color: getSentimentColor(sentiment),
      }));
      return result;
    }, {});
  } catch (e) {
    console.error('Error', e);
  }
  return sentimentCountsBySpeaker;
};

export const getSentimentOverallCount = (speechSegmentList) => {
  let result = [];
  try {
    const sentimentCounts = speechSegmentList.reduce((counts, item) => {
      const { sentiment } = item;
      counts[sentiment] = (counts[sentiment] || ZERO) + ONE;
      return counts;
    }, {});

    result = Object.entries(sentimentCounts).map(([label, value]) => ({
      label,
      value,
      color: getSentimentColor(label),
    }));
  } catch (error) {
    console.error('Error', error);
  }
  return result;
};
